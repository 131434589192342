import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CVFile, Worker } from 'src/app/core/model/worker';
@Injectable()
export class WorkersService {
    constructor(private http: HttpClient) { }

    private buildUrl(hash:string):string{
      return `${environment.api}/forms/workers/${hash}`
    }

    private buildCvUrl(hash:string):string{
      return `${this.buildUrl(hash)}/cv`
    }
    getWorker(hash: string) {
        return this.http.get<Worker>(this.buildUrl(hash));
    }

    updateWorker(hash: string, worker: Worker) {
        return this.http.put<Worker>(this.buildUrl(hash), worker);
    }
    getCV(hash: string) {
        return this.http.get<CVFile>(this.buildCvUrl(hash));
    }
    removeCV(hash: string) {
        return this.http.delete<any>(this.buildCvUrl(hash));
    }

}
