<div class="pt-5 pb-3 text-center">
  <img class="d-block mx-auto mb-4" src="/assets/images/logo-hf-3.png" alt="Hércules Formación">
  <h2>{{'app.registrationForm' | translate}}</h2>
  <p class="lead">
    {{'app.fillForm' | translate}}
  </p>
</div>
<div class="row g-5" *ngIf="!loading && worker">
  <div class="col">
    <form [formGroup]="workerForm" (submit)="save()">
      <h4 class="mb-3">{{'app.personalInformation' | translate}}</h4>
      <ul>
        <li *ngIf="fullName">
          <span>{{('app.name' | translate) +": "}}</span>
          <span>{{fullName}}</span>
        </li>
        <li *ngIf="worker && worker.document">
          <span>{{('app.DNI' | translate) +": "}}</span>
          <span>{{worker.document.number}}</span>
        </li>
      </ul>
      <hr class="my-1">
      <div class="row">
        <div class="col-md-6 mt-4" formGroupName="address">
          <div class="p-field">
            <span class="p-float-label">
              <input type="text" id="line1" class="w-100" pInputText formControlName="line1">
              <label for="line1">{{'app.streetLine1' | translate}}</label>
            </span>
          </div>
        </div>
        <div class="col-md-6 mt-4" formGroupName="address">
          <div class="p-field">
            <span class="p-float-label">
              <input type="text" id="line2" class="w-100" pInputText formControlName="line2">
              <label for="line2">{{'app.streetLine2' | translate}}</label>
            </span>
          </div>
        </div>
        <div class="col-md-6 mt-4" formGroupName="address">
          <div class="p-field">
            <span class="p-float-label">
              <input type="text" id="locality" class="w-100" pInputText formControlName="locality">
              <label for="locality">{{'app.locality' | translate}}</label>
            </span>
          </div>
        </div>
        <div class="col-md-3 mt-4" formGroupName="address">
          <div class="p-field">
            <span class="p-float-label">
              <input type="text" id="province" class="w-100" pInputText formControlName="province">
              <label for="province">{{'app.region' | translate}}</label>
            </span>
          </div>
        </div>
        <div class="col-md-3 mt-4" formGroupName="address">
          <div class="p-field">
            <span class="p-float-label">
              <input type="text" id="postcode" class="w-100" pInputText formControlName="postcode">
              <label for="postcode">{{'app.postalCode' | translate}}</label>
            </span>
          </div>
        </div>
        <div class="col-md-3 mt-4">
          <div class="p-field">
            <span class="p-float-label">
              <input type="text" id="phoneNumber" class="w-100" pInputText formControlName="phoneNumber">
              <label for="phoneNumber">{{'app.phone' | translate}}</label>
            </span>
          </div>
        </div>
        <div class="col-md-3 mt-4">
          <div class="p-field">
            <span class="p-float-label">
              <input type="text" id="mobilePhoneNumber" class="w-100" pInputText formControlName="mobilePhoneNumber">
              <label for="mobilePhoneNumber">{{'app.mobile' |translate}}</label>
            </span>
          </div>
        </div>
        <div class="col-md-6 mt-4">
          <div class="p-field">
            <span class="p-float-label">
              <input type="text" id="email" class="w-100" pInputText formControlName="email">
              <label for="email">{{'app.email' |translate}}</label>
            </span>
          </div>
        </div>
        <div class="col-md-6 mt-4">
          <div class="p-field">
            <span class="p-float-label">
              <p-calendar formControlName="dateOfBirthDate" firstDayOfWeek="1" dateFormat="dd/mm/yy" >
              </p-calendar>
              <label for="dateOfBirthDate">{{'app.dateOfBirth' |translate}}</label>
            </span>
          </div>
        </div>
        <div class="mt-3">
          <div class="mb-1">{{'app.drivingLicense' |translate}}</div>
          <div class="form-check form-check-inline">
            <input [value]="true" type="radio" class="form-check-input" data-val="true"
              data-val-required="The DrivingLicense field is required." placeholder="drivingLicense"
              formControlName="drivingLicense">
            <label class="form-check-label" for="drivingLicense">{{'app.yes' |translate}}</label>
          </div>
          <div class="form-check">
            <input [value]="false" type="radio" class="form-check-input" checked="checked" placeholder="drivingLicense"
              formControlName="drivingLicense">
            <label class="form-check-label" for="drivingLicense">{{'app.no' |translate}}</label>
          </div>
        </div>
      </div>
      <hr class="my-3">

      <p class="privacy">
        {{'app.privacy' |translate}}
      </p>
      <div class="form-check">
        <span class="field-validation-valid" data-valmsg-for="dppAccept" data-valmsg-replace="true"></span>
        <input class="form-check-input" type="checkbox" data-val="true"
          data-val-required="The DppAccept field is required." placeholder="dppAccept" formControlName="dppAccept"
          value="true">
        <label class="form-check-label" for="dppAccept">{{'app.readAndagree' |translate}}</label>
      </div>
      <div class="form-check mb-3">
        <span class="field-validation-valid" data-valmsg-for="whatsappAccept" data-valmsg-replace="true"></span>
        <input class="form-check-input" type="checkbox" data-val="true"
          data-val-required="The WhatsappAccept field is required." placeholder="whatsappAccept"
          formControlName="whatsappAccept" value="true">
        <label class="form-check-label" for="whatsappAccept">{{'app.authorizeWhatsapp' |translate}}</label>
      </div>
      <p class="mb-1 cv">{{'app.authorizeSendCV' |translate}}
      </p>
      <div class="form-check">
        <span class="field-validation-valid" data-valmsg-for="cvAccept" data-valmsg-replace="true"></span>
        <input class="form-check-input" type="checkbox" data-val="true"
          data-val-required="The CvAccept field is required." placeholder="cvAccept" formControlName="cvAccept"
          value="true">
        <label class="form-check-label" for="cvAccept">{{'app.authorizeCV' |translate}}</label>
      </div>
      <hr class="my-4">
      <button type="submit" class="w-100 btn btn-primary btn-lg">{{'app.send' |translate}}</button>
      <input name="__RequestVerificationToken" type="hidden"
        value="CfDJ8IQ7kIIc1YZFszTN-d9H8nrpxXOFV5y_8hTznHPC2QmvKwF3BT2hkIUPa5NcpkXBQ2Tj2fZWjJk_ecXy28eBSKRxhH5dQtEgEH8IZlo08ZB68BTVmme2KmhAAp2CCVrKbCjUopeVDCNneLnf14s5Ykg"><input
        name="dppAccept" type="hidden" value="false">
    </form>
  </div>
</div>
