import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { HomeComponent } from './pages/home/home.component';
import { CvComponent } from './pages/home/detail/cv.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: 'error', component: AppErrorComponent
            },
            { path: 'notfound', component: AppNotfoundComponent }
            ,
            {
                path: 'dpp/:hash/cv',
                component: CvComponent
            },
            {
                path: 'dpp/:hash',
                component: HomeComponent
            }
            ,
            { path: '**', redirectTo: '/notfound' }


        ], { scrollPositionRestoration: 'enabled' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
