import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";

@Injectable()
export class ToastNotifierService {
    constructor(private messageService: MessageService, private translate: TranslateService) { }

    public fail(message: string) {

        this.translate.get(["error.errorMessage", "error." + message]).subscribe({
            next: (translations) => {
                const translatedTitle = translations["error.errorMessage"];
                const translatedMessage = translations["error." + message];
                this.messageService.add({ key: 'bc', severity: 'error', summary: translatedTitle, detail: translatedMessage });
            }, error: (error) => {
                this.messageService.add({ key: 'bc', severity: 'error', summary: 'Mensaje de error', detail: message });
            }
        });
    }

    public success(message: string) {
        this.translate.get(["app.okMessage", "app." + message]).subscribe({
            next: (translations) => {
                const translatedTitle = translations["app.okMessage"];
                const translatedMessage = translations["app." + message];
                this.messageService.add({ key: 'bc', severity: 'success', summary: translatedTitle, detail: translatedMessage });
            }, error: (error) => {
                this.messageService.add({ key: 'bc', severity: 'success', summary: 'Mensaje de éxito', detail: message });
            }
        });


    }
}