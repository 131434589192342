import { DocumentIdentityType } from "../enums/document-identity-types";
import { LanguageLevel } from "../enums/language-level";

export class Worker {
    id: string;
    document?: Document;
    name?: string;
    surname1?: string;
    surname2?: string;
    address?: Address
    phoneNumber?: string;
    mobilePhoneNumber?: string;
    email?: string;
    freelance?: boolean;
    gender?: string;
    dppAccepted?: boolean;
    drivingLicense?: boolean;
    dateOfBirthDate?: Date;
    dateOfBirth?: string;

    //Datos trabajador
    skills?: string[];
    regulatedTrainings?: string[];
    nonRegulatedTrainings?: string[];
    languages?: Map<string, LanguageSkill[]>;
    languageSkills?: LanguageSkill[];
    jobs?: Job[];

    static getLanguageCode(worker: Worker): string {
        let languageCode = "es-ES";
        if (worker.address?.province?.toLowerCase() == "portugal")
            languageCode = "pt-PT";
        return languageCode;
    }
}

export interface Document {
    number?: string;
    type?: DocumentIdentityType;
}

export interface Address {
    line1?: string;
    line2?: string;
    postcode?: string;
    locality?: string;
    province?: string;
}

export interface Job {
    careerId: string;
    careerLevelId: string;
    start: string;
    end: string;
}
export interface Career {
    id: string;
    name?: string;
    description?: string;
}
export interface Skill {
    id: string;
    name?: string;
    description?: string;
}
export interface RegulatedTraining {
    id: string;
    name?: string;
    description?: string;
}

export interface NonRegulatedTraining {
    id: string;
    name?: string;
    description?: string;
}
export interface CareerLevel {
    id: string;
    name?: string;
    description?: string;
}
export interface Language {
    id: string;
    name: string;
    description: string;
}

export interface LanguageSkill {
    languageId: string;
    reading: LanguageLevel;
    speaking: LanguageLevel;
    writing: LanguageLevel;
}

export interface CVFile {
    date: Date;
    fileName: string;
    url: string;
}
