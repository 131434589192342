import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';

@Injectable()
export class LanguageAppService {
    constructor(private translateService: TranslateService, private primengConfig: PrimeNGConfig) { }

    public setLanguage(lang: string) {
        this.translateService.use(lang);
        this.translateService.get('primeng').subscribe(res => this.primengConfig.setTranslation(res));
    }
}