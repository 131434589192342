import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { toDateOnlyString, validateAllFormFields } from 'src/app/core/functions';
import { ToastNotifierService } from 'src/app/core/services/toast-notifier.service';
import { WorkersService } from '../../core/services/workers.service';
import { Worker } from 'src/app/core/model/worker';
import { requiredAndTrueValidator } from 'src/app/core/validators/required-and-true-validator';
import { LanguageAppService } from 'src/app/core/services/language-app.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})

export class HomeComponent implements OnInit {
  saving: boolean = false;
  loading: boolean = true;
  hash: string;
  worker: Worker;
  fullName: string = "";
  workerForm = this.fb.group({
    phoneNumber: ['', Validators.pattern('[- +()0-9]+')],
    mobilePhoneNumber: ['', Validators.compose([Validators.required, Validators.pattern('[- +()0-9]+')])],
    email: ['', Validators.compose([Validators.required, Validators.email])],
    dppAccept: [false, [Validators.required, requiredAndTrueValidator]],
    address: this.fb.group({
      line1: ['', Validators.required],
      line2: [''],
      locality: ['', Validators.required],
      province: ['', Validators.required],
      postcode: ['', Validators.required],
    }),
    dateOfBirthDate: ['', Validators.required],

    drivingLicense: ['', Validators.required],
    whatsappAccept: [false, [Validators.required, requiredAndTrueValidator]],
    cvAccept: [false, [Validators.required, requiredAndTrueValidator]],
  })
  certImages: any;

  constructor(private workersService: WorkersService,
    private fb: UntypedFormBuilder,
    private toastNotifierService: ToastNotifierService,
    private router: Router,
    private route: ActivatedRoute,
    private languageAppService: LanguageAppService
  ) {
    this.hash = this.route.snapshot.params.hash;

  }

  ngOnInit() {
    this.loading = true;
    this.workersService.getWorker(this.hash).subscribe({
      next: worker => {
        this.goToCv(worker);
        this.worker = worker;
        this.getFullName(this.worker);
        if (this.worker.dateOfBirth) {
          this.worker.dateOfBirthDate = new Date(this.worker.dateOfBirth);
        }
        this.workerForm.patchValue(worker);
        this.loading = false;

        const codeLanguage = Worker.getLanguageCode(worker);
        this.languageAppService.setLanguage(codeLanguage);
      },
      error: error => {
        if (error.status == 409 && error.error?.errors[0]?.startsWith("http")) {
          window.location.replace(error.error?.errors[0]);
        }
        this.loading = false;
        this.toastNotifierService.fail('loadData');
        this.router.navigate(["/error"]);
      }
    })
  }

  getFullName(worker: Worker) {
    this.fullName = '';
    if (worker.name) {
      this.fullName += worker.name.trim();
    }
    if (worker.surname1) {
      this.fullName += ' ' + worker.surname1.trim();
    }
    if (worker.surname2) {
      this.fullName += ' ' + worker.surname2.trim();
    }
  }

  goToCv(worker: Worker) {
    if (worker.dppAccepted) {
      const params = {
        state: {
          data: {
            worker: worker
          }
        }
      };

      this.router.navigate([`dpp/${this.hash}/cv`], params);
    }
  }

  save() {
    this.saving = true;
    if (this.workerForm.valid) {
      this.worker = { ...this.worker, ...this.workerForm.value };
      this.workerForm.disable();
      if (this.worker.dateOfBirthDate) {
        this.worker.dateOfBirth = toDateOnlyString(this.worker.dateOfBirthDate);
      }
      this.worker.dppAccepted = true;
      this.workersService.updateWorker(this.hash, this.worker).subscribe({
        next: (worker) => {
          this.saving = false;
          this.workerForm.enable();
          this.toastNotifierService.success('formSubmittedSuccessfully');
          this.goToCv(this.worker);
        },
        error: error => {
          this.saving = false;
          this.workerForm.enable();
          this.toastNotifierService.fail('sendData');
        }
      });
    }
    else {
      this.saving = false;
      this.workerForm.enable();
      validateAllFormFields(this.workerForm);
      this.toastNotifierService.fail('reviewfields');
    }
  }


  addNewLogo() {
    if (this.certImages.length < 6)
      this.certImages.push({ id: "", fileName: "", position: null, modified: true });
  }

  cvClick() {
    this.router.navigate(['/cv']);
  }

}
