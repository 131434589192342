import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppComponent, createTranslateLoader } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ToastNotifierService } from 'src/app/core/services/toast-notifier.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToolbarModule } from 'primeng/toolbar';
import { AppRoutingModule } from './app-routing.module';
import { CvComponent } from './pages/home/detail/cv.component';
import { SkeletonModule } from 'primeng/skeleton';
import { DateOnlyComponent } from "src/app/components/dateonly/dateonly.component";
import { CalendarModule } from 'primeng/calendar';
import { RouterModule } from '@angular/router';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CommonModule } from "@angular/common";
import { MultiSelectModule } from "primeng/multiselect";
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FileUploadModule } from "primeng/fileupload";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { WorkersService } from './core/services/workers.service';
import localeEs from '@angular/common/locales/es';
import localeExtraEs from '@angular/common/locales/extra/es';
import { registerLocaleData } from '@angular/common';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { CheckboxModule } from 'primeng/checkbox';
import { MonthYearModule } from './components/month-year/month-year.module';
import { LanguageAppService } from './core/services/language-app.service';
import { CvDataService } from './core/services/cv-data.service';
registerLocaleData(localeEs, 'es-ES', localeExtraEs);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CvComponent,
    DateOnlyComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    AutoCompleteModule,
    BrowserAnimationsModule,
    ToolbarModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SkeletonModule,
    RouterModule,
    CalendarModule,
    DropdownModule,
    MultiSelectModule,
    ButtonModule,
    ToastModule,
    ProgressSpinnerModule,
    FileUploadModule,
    ConfirmDialogModule,
    InputTextModule,
    CheckboxModule,
    MonthYearModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    MessageService,
    ToastNotifierService,
    LanguageAppService,
    WorkersService,
    CvDataService,
    ConfirmationService,
    TranslateService,
    {
      provide: LOCALE_ID,
      useValue: "es-ES"
    },

  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
