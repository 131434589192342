
import { DatePipe } from "@angular/common";
import { Component, Input, OnChanges } from "@angular/core";
import { FormControl } from "@angular/forms";
import moment from "moment";
import { toDateOnlyString } from "src/app/core/functions";

@Component({
    templateUrl: './month-year.component.html',
    selector: 'app-month-year'
})

export class MonthYearComponent implements OnChanges {
    @Input() control: FormControl;
    @Input() label: string;
    @Input() labelYear: string;
    @Input() labelMonth: string;
    @Input() disabled: boolean;
    @Input() lastDayOfMonth: boolean;
    @Input() minDate?: Date;
    date: Date;
    month: number;
    year: number;
    minMonth: number;
    minYear: number;
    years = [];
    months = [];
    classErrorControl: string = "ng-invalid ng-touched ng-dirty";


    constructor(private datePipe: DatePipe) {
        this.setYears();
        this.setMonths();
    }

    ngOnChanges(): void {
        this.configMinYearAndMonth();
        if (this.control.value) {
            this.date = new Date(this.control.value);
            this.year = this.date.getFullYear();
            this.month = this.date.getMonth();
        }
        else {
            this.date = null;
        }
    }

    setYears() {
        let currentYear: number = new Date().getFullYear();
        for (let i = 0; i <= 67; i++) {
            let year = currentYear - i;
            let disabled = (this.minYear && this.minYear > year);
            this.years.push({ label: year, value: year, disabled: disabled });
        }
    }
    setMonths() {
        for (let i = 0; i < 12; i++) {
            const date = new Date(2000, i, 1); // Usamos el año 2000 para evitar problemas con años bisiestos
            let disabled = this.minMonth && this.minMonth > i;
            this.months.push({ label: this.datePipe.transform(date, 'LLLL'), value: i, disabled: disabled });
        }
    }
    configMinYearAndMonth() {
        if (this.minDate) {
            const minDate = new Date(this.minDate);
            this.minYear = minDate.getFullYear();
            this.minMonth = minDate.getMonth();
            this.months.forEach(m => { m.disabled = m.value < this.minMonth; });
            this.years.forEach(y => { y.disabled = y.value < this.minYear; });
        }
        else {
            this.months.forEach(m => { m.disabled = false; });
            this.years.forEach(y => { y.disabled = false; });
        }

    }


    updateDate(event) {
        if (this.month && this.year) {
            let momentDate = moment().year(this.year).month(this.month);
            this.date = this.lastDayOfMonth ? momentDate.endOf("month").toDate() : momentDate.startOf("month").toDate();
            let value = toDateOnlyString(this.date);
            this.control.setValue(value);
        }
        else {
            this.control.setValue(null);
        }
    }
}

