import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';


@Component({
    selector: 'app-error',
    templateUrl: './app.error.component.html',
})
export class AppErrorComponent {
    constructor(public app: AppComponent, private router: Router) {
    }

}