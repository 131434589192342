<div class="pt-5 pb-3 text-center">
    <img class="d-block mx-auto mb-4" src="/assets/images/logo-hf-3.png" alt="Hércules Formación">
    <h2>{{'app.curriculum' | translate}}</h2>
    <p class="lead">
        {{'app.fillCurriculum' | translate}}
    </p>
    <div class="spinner" *ngIf="loading">
        <p-progressSpinner class="spinner"></p-progressSpinner>
    </div>
</div>

<div *ngIf="!loading && worker">
    <div [formGroup]="workerForm" (submit)="save()">
        <div class="row g-5">
            <div class="col">
                <h4 class="mb-3">{{'app.generalData' | translate}}</h4>
                <ul>
                    <li *ngIf="fullName">
                        <span>{{('app.name' | translate)+": "}}</span>
                        <span>{{fullName}}</span>
                    </li>
                    <li *ngIf="worker.document">
                        <span>{{('app.DNI' | translate)+": "}}</span>
                        <span>{{worker.document.number}}</span>
                    </li>
                    <li *ngIf="worker.address">
                        <span>{{('app.street' | translate)+": "}}</span>
                        <span *ngIf="worker.address.line1">{{worker.address.line1}}</span>
                        <span *ngIf="worker.address.line2"><br />{{worker.address.line2}}</span>
                    </li>
                    <li *ngIf="worker.address">
                        <span>{{('app.locality' | translate)+": "}} </span>
                        <span>{{worker.address.locality}}</span>
                    </li>
                    <li *ngIf="worker.address">
                        <span>{{('app.region' | translate)+": "}}</span>
                        <span>{{worker.address.province}}</span>
                    </li>
                    <li *ngIf="worker.address">
                        <span>{{('app.postalCode' | translate)+": "}}</span>
                        <span>{{worker.address.postcode}}</span>
                    </li>
                    <li>
                        <span>{{('app.phone' | translate)+": "}}</span>
                        <span>{{worker.phoneNumber}}</span>
                    </li>
                    <li>
                        <span>{{('app.mobile' | translate)+": "}}</span>
                        <span>{{worker.mobilePhoneNumber}}</span>
                    </li>
                    <li>
                        <span>{{('app.email' | translate)+": "}}</span>
                        <span>{{worker.email}}</span>
                    </li>
                    <li>
                        <span>{{('app.drivingLicense' | translate)+": "}}</span>
                        <span>{{worker.drivingLicense?('app.yes' | translate):('app.no' | translate)}}</span>
                    </li>
                </ul>
                <hr class="my-1 mb-2 mt-2">
            </div>
        </div>
        <div class="row">
            <h5>{{('app.file' | translate)}}</h5>
            <div class="col col-md-4  mb-3">
                <p-fileUpload name="file" [url]="apiUploadCV" method="put" (onUpload)="onUploadCV($event)"
                    (onBeforeUpload)="onBeforeUploadCV($event)" (onError)="onErrorCV($event)"
                    [maxFileSize]="maxFileSize" mode="basic" [auto]="true" [disabled]="loading||saving"
                    styleClass="mr-2 w-auto" [chooseLabel]="chooseLabel">
                </p-fileUpload>
            </div>
            <div *ngIf="cvFile" class="col col-md-4  mb-3">
                <label>{{('app.uploadDate' | translate) +" " + (cvFile.date | date :"dd/MM/yyyy HH:mm:ss")}}</label>
            </div>
            <div class="col-8 col-md-4 mb-3" *ngIf="cvFile"> <p-button icon="pi pi-file-pdf" (click)="downloadCV()"
                    type="button" [disabled]="saving ||loading||!cvFile"
                    [label]="'app.downloadCurriculum' | translate"></p-button>
            </div>
            <div class="col-8 col-md-4 mb-3" *ngIf="cvFile">
                <p-button icon="pi pi-trash" styleClass="p-button-danger" (click)="removeCV()" type="button"
                    [disabled]="saving ||loading ||!cvFile" [label]="'app.deleteCurriculum' | translate"></p-button>
            </div>
            <hr class="my-1 mb-2 mt-2">
        </div>
        <div class="row">
            <h5>{{'app.capabilities' | translate}}</h5>
            <p-multiSelect [options]="skills" optionLabel="name" optionValue="id" formControlName="skills"
                display="chip" styleClass="w-100">
            </p-multiSelect>
            <hr class="my-1 mb-2 mt-2">
        </div>
        <div class="row">
            <h5>{{'app.regulatedTraining' | translate}}</h5>
            <p-multiSelect [options]="regulatedTrainings" optionLabel="name" optionValue="id"
                formControlName="regulatedTrainings" display="chip" styleClass="w-100">
            </p-multiSelect>
            <hr class="my-1 mb-2 mt-2">
        </div>
        <div class="row">
            <h5>{{'app.nonRegulatedTraining' | translate}}</h5>
            <p-multiSelect [options]="nonRegulatedTrainings" optionLabel="name" optionValue="id"
                formControlName="nonRegulatedTrainings" display="chip" styleClass="w-100">
            </p-multiSelect>
            <hr class="my-1 mb-2 mt-2">
        </div>
        <div class="row">
            <h5>{{'app.experience' | translate}}</h5>
            <div class="col col-md-4 mb-3">
                <button pButton pRipple icon="pi pi-plus" (click)="addJob()" type="button" [disabled]="saving"
                    [label]="'app.addExperience' | translate"></button>
            </div>
            <div class="col-12" formArrayName="jobs" *ngFor="let job of jobsForm.controls; let i = index">
                <div class="row mb-1 pt-4 pb-0 pr-2 pl-2 bg-light border" [formGroupName]="i">
                    <div class="col-12 mb-4 col-md-6 col-lg-7">
                        <div class="p-field">
                            <span class="p-float-label">
                                <p-dropdown [options]="careers" formControlName="careerId" optionLabel="name"
                                    styleClass="w-100" [autoDisplayFirst]="false" optionValue="id" [showClear]="true">
                                </p-dropdown>
                                <label for="career">{{'app.profession' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="col-12 mb-4 col-md-6 col-lg-5">
                        <div class="p-field">
                            <span class="p-float-label">
                                <p-dropdown [options]="careerLevels" formControlName="careerLevelId" optionLabel="name"
                                    styleClass="w-100" [autoDisplayFirst]="false" optionValue="id" [showClear]="true">
                                </p-dropdown>
                                <label for="career">{{'app.category' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="col-12 mb-4 col-md-12 col-lg-2 lg-mb-0 d-flex flex-column justify-content-center">
                        <div class="p-field">
                            <span class="p-float-label">
                                <p-checkbox formControlName="currentJob" [binary]="true" inputId="binary"
                                    [disabled]="saving" (onChange)="onChangeCurrentJob($event, job)"></p-checkbox>
                                {{'app.currentJob' | translate}}
                            </span>
                        </div>
                    </div>
                    <div class="col-12 mb-4 col-md-6 col-lg-5">
                        <app-month-year [label]="'app.form' | translate" [(disabled)]="saving"
                            [(control)]="job.controls['start']" [labelYear]="'app.year' | translate"
                            [labelMonth]="'app.month' | translate"></app-month-year>
                    </div>
                    <div class="col-12 mb-4 col-md-6 col-lg-5">
                        <app-month-year *ngIf="!job.controls['currentJob'].value" [label]="'app.until' | translate"
                            [(disabled)]="saving" [(control)]="job.controls['end']" [lastDayOfMonth]="true"
                            [minDate]="job.controls['start'].value" [labelYear]="'app.year' | translate"
                            [labelMonth]="'app.month' | translate"></app-month-year>
                        <div class=" p-field" *ngIf="job.controls['currentJob'].value">
                            <span class="p-float-label">
                                <input type="text" pInputText [(ngModel)]="defaultEndValue"
                                    [ngModelOptions]="{standalone: true}" [disabled]="true" class="col-12" />
                                <label for="career">{{'app.until' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="col text-end mb-4">
                        <button pButton pRipple class="p-button-danger" icon="pi pi-trash" (click)="deleteJob(i)"
                            type="button" [disabled]="saving"></button>
                    </div>
                </div>
            </div>
            <hr class="my-1 mb-2 mt-2">
        </div>
        <div class="row">
            <h5>{{'app.languages' | translate}}</h5>
            <div class="col-8 col-md-4 mb-3">
                <button pButton pRipple icon="pi pi-plus" (click)="addLanguageSkill()" type="button" [disabled]="saving"
                    [label]="'app.addLanguage' | translate"></button>
            </div>
            <div class="col-12" formArrayName="languageSkills"
                *ngFor="let languageSkill of languageSkillsForm.controls; let j = index">
                <div class="row mb-1 pt-4 pb-0 pr-2 pl-2 bg-light border" [formGroupName]="j">
                    <div class="col-12 mb-4 col-md-6 col-lg-2">
                        <div class="p-field">
                            <span class="p-float-label">
                                <p-dropdown [options]="languages" formControlName="languageId" optionLabel="name"
                                    [autoDisplayFirst]="false" optionValue="id" [showClear]="true" styleClass="w-100"
                                    id="{{'languageId' + j}}">
                                </p-dropdown>
                                <label for="{{'languageId' + j}}">{{'app.language' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="col-12 mb-4 col-md-6 col-lg-3">
                        <div class="p-field">
                            <span class="p-float-label">
                                <p-dropdown [options]="languageLevels" formControlName="reading" optionLabel="label"
                                    [autoDisplayFirst]="false" optionValue="value" [showClear]="true" styleClass="w-100"
                                    id="{{'reading' + j}}">
                                </p-dropdown>
                                <label for="{{'reading' + j}}">{{'app.read' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="col-12 mb-4 col-md-6 col-lg-3">
                        <div class="p-field">
                            <span class="p-float-label">
                                <p-dropdown [options]="languageLevels" formControlName="speaking" optionLabel="label"
                                    [autoDisplayFirst]="false" optionValue="value" [showClear]="true" styleClass="w-100"
                                    id="{{'speaking' + j}}">
                                </p-dropdown>
                                <label for="{{'speaking' + j}}">{{'app.speak' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="col-12 mb-4 col-md-6 col-lg-3">
                        <div class="p-field">
                            <span class="p-float-label">
                                <p-dropdown [options]="languageLevels" formControlName="writing" optionLabel="label"
                                    [autoDisplayFirst]="false" optionValue="value" [showClear]="true" styleClass="w-100"
                                    id="{{'writing' + j}}">
                                </p-dropdown>
                                <label for="{{'writing' + j}}">{{'app.written' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="col text-end mb-4">
                        <button pButton pRipple icon="pi pi-trash" class="p-button-danger"
                            (click)="deleteLanguageSkill(j)" type="button" [disabled]="saving"></button>
                    </div>
                </div>
            </div>
            <hr class="my-1 mb-2 mt-2">
        </div>

        <button type="submit" class="w-100 btn btn-primary btn-lg" (click)="save()">{{'app.send' | translate}}</button>
    </div>
</div>
<p-confirmDialog key="confirmAction" icon="pi pi-exclamation-triangle" [acceptLabel]="'app.accept' | translate"
    [rejectLabel]="'app.cancel' | translate">
</p-confirmDialog>
