import { NgModule } from "@angular/core";
import { MonthYearComponent } from "./month-year.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DropdownModule } from 'primeng/dropdown';
import { CommonModule, DatePipe } from "@angular/common";
@NgModule({
    declarations: [
        MonthYearComponent
    ],
    imports: [
        FormsModule,
        DropdownModule,
        CommonModule,
        ReactiveFormsModule,
    ],
    exports: [
        MonthYearComponent
    ],
    providers: [
        DatePipe
    ]
})

export class MonthYearModule { }
