<div class="row custom-p-filled-label" *ngIf="control">
  <span class="p-float-label-custom">
    <label>{{label}}</label>
  </span>
  <div class="col-6">
    <div class="p-field">
      <span class="p-float-label">
        <p-dropdown [options]="years" [(ngModel)]="year" [autoDisplayFirst]="false" [showClear]="true"
          [disabled]="disabled" optionLabel="label" optionValue="value" optionDisabled="disabled"
          [class]="control && control.status == 'INVALID'&& control.dirty && !year ?'w-100 ' + classErrorControl:'w-100'"
          (onChange)="updateDate($event)">
        </p-dropdown>
        <label for="career">{{labelYear}}</label>
      </span>
    </div>
  </div>
  <div class="col-6">
    <div class="p-field">
      <span class="p-float-label">
        <p-dropdown [options]="months" [(ngModel)]="month" optionLabel="label" [autoDisplayFirst]="false"
          optionValue="value" [showClear]="true" [disabled]="disabled" optionDisabled="disabled"
          [class]="control && control.status == 'INVALID'&& control.dirty && !month && month!=0  ? 'w-100 ' + classErrorControl:'w-100'"
          (onChange)="updateDate($event)">
        </p-dropdown>
        <label for="career">{{labelMonth}}</label>
      </span>
    </div>
  </div>
</div>