import { Component, OnInit } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { combineLatest } from 'rxjs';
import { LanguageLevelType } from 'src/app/core/enums/language-level';
import { validateAllFormFields } from 'src/app/core/functions';
import { CVFile, Career, CareerLevel, Job, Language, LanguageSkill, NonRegulatedTraining, RegulatedTraining, Skill, Worker } from 'src/app/core/model/worker';
import { LanguageAppService } from 'src/app/core/services/language-app.service';
import { ToastNotifierService } from 'src/app/core/services/toast-notifier.service';
import { WorkersService } from 'src/app/core/services/workers.service';
import { environment } from 'src/environments/environment';
import { CvDataService } from 'src/app/core/services/cv-data.service';
@Component({
    selector: 'app-cv',
    templateUrl: './cv.component.html',
})

export class CvComponent implements OnInit {
    hash: string;
    fullName: string
    worker: Worker;
    workerForm: UntypedFormGroup;
    saving: boolean = false;
    loading: boolean = true;
    careers: Career[] = [];
    career: Career;
    skills: Skill[];
    regulatedTrainings: RegulatedTraining[];
    nonRegulatedTrainings: NonRegulatedTraining[];
    careerLevels: CareerLevel[];
    languageLevels: LanguageLevelType[] = LanguageLevelType.types;
    languages: Language[];
    cvFile: CVFile = null;
    apiUploadCV: string;
    maxFileSize: number = 10000000;
    chooseLabel: string = "Añadir curriculum";
    defaultEndValue = "Actualidad";
    wordKeys = [{ key: "app.editCurriculum", value: "Modificar curriculum" },
    { key: "app.addCurriculum", value: "Añadir curriculum" },
    { key: "app.present", value: "Actualidad" },
    { key: "app.deleteCurriculum", value: "Eliminar curriculum" },
    { key: "app.askDeleteCurriculum", value: "¿Estás seguro de que desea eliminar el curriculum?" }
    ]
    constructor(
        private workersService: WorkersService,
        private dataService: CvDataService,
        private fb: UntypedFormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private toastNotifierService: ToastNotifierService,
        private confirmationService: ConfirmationService,
        private languageAppService: LanguageAppService,
        private translateService: TranslateService,

    ) {
        this.hash = this.route.snapshot.params.hash;
        this.workerForm = new UntypedFormGroup({
            career: new UntypedFormControl(null),
            skills: new UntypedFormControl(null),
            regulatedTrainings: new UntypedFormControl(null),
            nonRegulatedTrainings: new UntypedFormControl(null),
            languageSkills: this.fb.array([]),
            jobs: this.fb.array([])
        })
    }

    ngOnInit() {
        let query: LazyLoadEvent = {
            globalFilter: '',
            sortField: 'name',
            sortOrder: 0,
            first: 0,
            rows: 100000,
        }
        this.loading = true;
        combineLatest([
            this.dataService.getCareers(this.hash, query),
            this.dataService.getCareerLevels(this.hash, query),
            this.dataService.getSkills(this.hash, query),
            this.dataService.getRegulatedTrainings(this.hash, query),
            this.dataService.getNonRegulatedTrainings(this.hash, query),
            this.dataService.getLanguages(this.hash, query)
        ]).subscribe({
            next: (result) => {
                this.careers = result[0].items;
                this.careerLevels = result[1].items;
                this.skills = result[2].items;
                this.regulatedTrainings = result[3].items;
                this.nonRegulatedTrainings = result[4].items;
                this.languages = result[5].items;
                this.getWorker();
            },
            error: (e) => {
                this.loading = false;
                this.toastNotifierService.fail('loadData');
                this.router.navigate(["/error"]);
            }
        });
    }

    private getAllTranslates() {
        this.translateService.get(this.wordKeys.map(k => k.key)).subscribe({
            next: translations => {
                this.wordKeys.forEach(wk => { wk.value = translations[wk.key]; });
                this.chooseLabel = this.getTranslates("app.addCurriculum");
                this.defaultEndValue = this.getTranslates("app.present");
            },
            error: error => {
            }
        })
    }

    private getTranslates(key: string): string {
        return this.wordKeys.find(wk => wk.key == key).value;
    }

    private getWorker() {
        if (history?.state?.data) {
            this.worker = history.state.data.worker;
            history.pushState({}, '');
            this.loadWorker();
        } else {
            this.workersService.getWorker(this.hash).subscribe({
                next: worker => {
                    if (!worker?.dppAccepted) {
                        this.router.navigate([`${this.hash}`]);
                    }
                    this.workerForm.patchValue(worker);
                    this.worker = worker;
                    this.loadWorker();
                },
                error: () => {
                    this.loading = false;
                    this.toastNotifierService.fail('loadData');
                    this.router.navigate(['/error']);
                }
            });
        }
    }

    private loadWorker() {
        const codeLanguage = Worker.getLanguageCode(this.worker);
        this.languageAppService.setLanguage(codeLanguage);
        this.getAllTranslates();
        this.apiUploadCV = `${environment.api}/forms/workers/${this.hash}/cv`;
        this.getFullName(this.worker);
        this.workerForm.patchValue(this.worker);
        if (this.worker.jobs) {
            this.worker.jobs.forEach((job) => {
                this.addJob(job.careerId, job.careerLevelId, job.start, job.end);
            });
        }
        if (this.worker.languages) {
            for (let key in this.worker.languages) {
                let value = this.worker.languages[key];
                this.addLanguageSkill(key, value.reading, value.speaking, value.writing);
            }
        }
        this.getCvFile();
    }

    getFullName(worker: Worker) {
        this.fullName = '';
        if (worker.name) {
            this.fullName += worker.name.trim();
        }
        if (worker.surname1) {
            this.fullName += ' ' + worker.surname1.trim();
        }
        if (worker.surname2) {
            this.fullName += ' ' + worker.surname2.trim();
        }
    }

    getCvFile() {
        this.workersService.getCV(this.hash).subscribe({
            next: (result) => {
                this.cvFile = result;
                this.chooseLabel = this.cvFile ? this.getTranslates("app.editCurriculum") : this.getTranslates("app.addCurriculum");
                this.loading = false;
                this.saving = false;
            },
            error: error => {
                this.loading = false;
                this.saving = false;
                this.cvFile = null;
            }
        });
    }

    /****** Curriculum *****/
    //--Subida ficheros
    onUploadCV(event) {
        this.getCvFile();
        this.toastNotifierService.success("curriculumUploadedSuccessfully");
    }
    onErrorCV(event) {
        this.saving = false;
        this.toastNotifierService.fail("curriculumFailedDelete")
    }
    onBeforeUploadCV(event) {
        this.saving = true;
    }

    //--Borrado fichero
    removeCV() {
        this.confirmationService.confirm({
            header: this.getTranslates("app.deleteCurriculum"),
            key: "confirmAction",
            message: this.getTranslates("app.askDeleteCurriculum"),
            accept: () => {
                this.saving = true;
                this.workersService.removeCV(this.hash).subscribe({
                    next: () => {
                        this.cvFile = null;
                        this.chooseLabel = this.getTranslates("app.addCurriculum"),
                            this.saving = false;
                        this.toastNotifierService.success('curriculumDeletedSuccessfully');
                    },
                    error: error => {
                        this.toastNotifierService.fail("curriculumFailedDelete");
                        this.saving = false;
                    }
                });
            }
        });
    }

    //--Descarga fichero
    downloadCV() {
        const fullUrl = `${this.cvFile.url}`;
        const tempDownload = document.createElement('a')
        tempDownload.href = fullUrl;
        tempDownload.target = '_blank';
        tempDownload.download = fullUrl.split('/').pop();
        document.body.appendChild(tempDownload)
        tempDownload.click()
        document.body.removeChild(tempDownload)
    }

    /******** Jobs ********* */
    get jobsForm(): FormArray {
        return <FormArray>this.workerForm.get('jobs');
    }
    addJob(careerId?, careerLevelId?, start?, end?) {
        const isCurrentJob: boolean = !end && !!start;
        const jobForm = this.fb.group({
            careerId: [careerId, Validators.required],
            careerLevelId: [careerLevelId, Validators.required],
            start: [start, Validators.required],
            end: [end, Validators.required],
            currentJob: [isCurrentJob]
        })
        this.configJobFormEndValidators(jobForm, isCurrentJob);
        this.jobsForm.push(jobForm);
    }
    deleteJob(index: number) {
        this.jobsForm.removeAt(index);
    }

    updateJob(index: number, job: Job) {
        this.jobsForm.at(index).patchValue(job);
    }

    onChangeCurrentJob(event, jobForm: UntypedFormGroup) {
        if (event.checked)
            jobForm.controls["end"].setValue(null);
        this.configJobFormEndValidators(jobForm, event.checked);
    }

    configJobFormEndValidators(jobForm: UntypedFormGroup, isCurrentJob: boolean) {
        if (!isCurrentJob) {
            jobForm.controls['end'].setValidators([Validators.required]);
            jobForm.controls['end'].updateValueAndValidity();
        }
        else {
            jobForm.controls['end'].clearValidators();
            jobForm.controls['end'].updateValueAndValidity();
        }
    }

    /******** languageSkills ********* */
    get languageSkillsForm(): FormArray {
        return <FormArray>this.workerForm.get('languageSkills');
    }
    addLanguageSkill(languageId?, reading?, speaking?, writing?) {
        const jobForm = this.fb.group({
            languageId: [languageId, Validators.required],
            reading: [reading, Validators.required],
            speaking: [speaking, Validators.required],
            writing: [writing, Validators.required],
        })
        this.languageSkillsForm.push(jobForm);
    }

    deleteLanguageSkill(index: number) {
        this.languageSkillsForm.removeAt(index);
    }

    updateLanguageSkill(index: number, languageSkill: LanguageSkill) {
        this.languageSkillsForm.at(index).patchValue(languageSkill);
    }

    /******** Save ********* */
    save() {
        this.saving = true;
        if (this.workerForm.valid) {
            this.workerForm.disable();
            this.worker = { ...this.worker, ...this.workerForm.value };
            this.worker.languages = this.worker.languageSkills ?
                Object.assign({}, ...this.worker.languageSkills.map((x) => ({ [x.languageId]: x })))
                : null;

            this.workersService.updateWorker(this.hash, this.worker).subscribe({
                next: () => {
                    this.saving = false;
                    this.workerForm.enable();
                    this.toastNotifierService.success('dataSaveSuccessfully');
                },
                error: error => {
                    this.saving = false;
                    this.toastNotifierService.fail('noSaveData');
                }
            });
        }
        else {
            this.saving = false;
            this.workerForm.enable();
            validateAllFormFields(this.workerForm);
            this.toastNotifierService.fail("reviewfields");
        }
    }
}
