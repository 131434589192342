import { FormArray, UntypedFormControl, UntypedFormGroup } from "@angular/forms";

export function validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof UntypedFormControl) {
            control.markAsTouched({ onlySelf: true });
            control.markAsDirty({ onlySelf: true });
        } else if (control instanceof UntypedFormGroup) {
            validateAllFormFields(control);
        } else if (control instanceof FormArray) {
            control.controls.forEach(item => {
                if (item instanceof UntypedFormControl) {
                    item.markAsTouched({ onlySelf: true });
                    item.markAsDirty({ onlySelf: true });
                }
                else {
                    let itemGroup: UntypedFormGroup = item as UntypedFormGroup;
                    validateAllFormFields(itemGroup);
                }
            });
        }
    });
}

export function toDateOnlyString(date: Date) {
    if (!date)
        return null;
    else
        return `${date.getFullYear()}-${returnMonth()}-${returnDay()}`;

    function returnDay() {
        var d = (date.getDate() < 10) ? "0" + (date.getDate()) : date.getDate();
        return d;
    }
    function returnMonth() {
        var m = (date.getMonth() + 1 < 10) ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
        return m;
    }
}