import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LazyLoadEvent } from "primeng/api";
import { HttpParamsBuilder } from "../http-params.builder";
import { PaginatedResult } from "../model/paginated-result";
import { Career, CareerLevel, Language, NonRegulatedTraining, RegulatedTraining, Skill } from "../model/worker";
import { environment } from "src/environments/environment";

@Injectable()
export class CvDataService {
  constructor(private http: HttpClient) { }

  private buildCvDataUrl(item: string, hash: string): string {
    return `${environment.api}/forms/cv-data/${item}/${hash}`;
  }

  getCareerLevels(hash: string, event: LazyLoadEvent) {
    let params = HttpParamsBuilder.fromLazyEvent(event);

    return this.http.get<PaginatedResult<CareerLevel>>(this.buildCvDataUrl('career-levels', hash), { params: params });
  }

  getCareers(hash: string, event: LazyLoadEvent) {
    let params = HttpParamsBuilder.fromLazyEvent(event);

    return this.http.get<PaginatedResult<Career>>(this.buildCvDataUrl('careers', hash), { params: params });
  }

  getLanguages(hash: string, event: LazyLoadEvent) {
    let params = HttpParamsBuilder.fromLazyEvent(event);
    return this.http.get<PaginatedResult<Language>>(this.buildCvDataUrl('languages', hash), { params: params });
  }

  getNonRegulatedTrainings(hash: string, event: LazyLoadEvent) {
    let params = HttpParamsBuilder.fromLazyEvent(event);
    return this.http.get<PaginatedResult<NonRegulatedTraining>>(this.buildCvDataUrl('non-regulated-trainings', hash), { params: params });
  }

  getRegulatedTrainings(hash: string, event: LazyLoadEvent) {
    let params = HttpParamsBuilder.fromLazyEvent(event);

    return this.http.get<PaginatedResult<RegulatedTraining>>(this.buildCvDataUrl('regulated-trainings', hash), { params: params });
  }
  getSkills(hash: string, event: LazyLoadEvent) {
    let params = HttpParamsBuilder.fromLazyEvent(event);

    return this.http.get<PaginatedResult<Skill>>(this.buildCvDataUrl('skills', hash), { params: params })
  }
}
