

export enum LanguageLevel {
    A1 = "a1",
    A2 = "a2",
    B1 = "b1",
    B2 = "b1",
    C1 = "c1",
    C2 = "c2",
}

export class LanguageLevelType {
    label: string;
    value: LanguageLevel;
    static types: LanguageLevelType[] = [
        { value: LanguageLevel.A1, label: 'A1 - Básico' },
        { value: LanguageLevel.A2, label: 'A2 - Básico' },
        { value: LanguageLevel.B1, label: 'B1 - Intermedio' },
        { value: LanguageLevel.B2, label: 'B2 - Intermedio' },
        { value: LanguageLevel.C1, label: 'C1 - Avanzado' },
        { value: LanguageLevel.C2, label: 'C2 - Avanzado' }
    ]
    static mapLevel(status: LanguageLevel): string {
        return this.types.filter(t => t.value == status).map<string>(f => f.label)[0];
    }
}